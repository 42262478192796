<template>
    <div>
        <h1 class="mb-md-0 mt-0">{{ translate('title') }}</h1>
        <div class="banners">
            <div id="banner-right" class="banner">
                <component
                    :is="defaultTheme || getActiveMarketingPromotion ? 'div' : 'nuxt-link'"
                    class="big-hero-image-wrapper"
                    @click="clickPromotionBanner()"
                >
                    <UIImage
                        class="banner-picture"
                        :src="bannerRightImage"
                        :resource="true"
                        alt="Banner"
                        :raw-src="rawSrc"
                        :lazy="false"
                    />
                </component>
            </div>

            <div id="banner-left" class="banner">
                <div v-if="screenWidth > 767" class="big-hero-image-wrapper">
                    <UIImage
                        class="banner-picture"
                        :src="bannerLeftImage"
                        :resource="true"
                        alt="Banner"
                        :lazy="false"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UIImage } from '@dundle/ui/components';
import TranslationMixin from '~/mixins/TranslationMixin';
import MarketingMixin from '~/mixins/MarketingMixin';

export default {
    name: 'HeaderImageComponent',
    components: {
        UIImage,
    },

    mixins: [TranslationMixin, MarketingMixin],
    data() {
        return {
            screenWidth: 540,
        };
    },
    computed: {
        ...mapGetters({
            theme: 'occasion/getActiveOccasion',
        }),
        defaultTheme() {
            return this.theme === 'default';
        },
        rawSrc() {
            if (this.getActiveMarketingPromotion()) {
                return this.marketingBanner.rawSrc;
            }
            return false;
        },
        bannerRightImage() {
            if (this.getActiveMarketingPromotion()) {
                return this.marketingBanner.banner;
            }
            if (this.theme === 'app-promotion' && this.supportsPromotion('app')) {
                const occasion = this.theme;
                if (this.theme === 'app-promotion') {
                    return `images/occasion/app/new/2022-05/Home-Page-Banner-Desktop-${this.$locale.language.toUpperCase()}.png`;
                }
                return `https://images.dundle.com/resources/images/banners/homepage-banner-desktop-${occasion}.jpg`;
            } else {
                return `images/banners/00-general/cards/banner-cards-${this.$locale.language.toUpperCase()}.png`;
            }
        },
        bannerLeftImage() {
            return `images/banners/00-general/home/2023/general-banner-${this.$locale.language.toUpperCase()}.png`;
        },
    },
    mounted() {
        this.screenWidth = window.innerWidth;
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    },
    methods: {
        clickPromotionBanner() {
            const promotion = this.getActiveMarketingPromotion();
            this.trackPromotionClick(promotion);

            const path = this.getActivePromotionPath();

            if (path) {
                return this.$router.push(path);
            }

            // Default navigate to search
            return this.$router.push(this.$locale.path({ name: 'search' }));
        },
        onResize() {
            this.screenWidth = window.innerWidth;
        },
        trackGooglePlayDownload() {
            this.$gtm?.push({ event: 'event_app_download_google_play_banner' });
        },
        trackAppStoreDownload() {
            this.$gtm?.push({ event: 'event_app_download_app_store_banner' });
        },
    },
};
</script>

<style lang="scss" scoped>
.banners {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;

    .banner {
        height: auto;
        position: relative;
        flex-grow: 1;

        &:hover {
            cursor: pointer;
        }

        .big-hero-image-wrapper {
            overflow: hidden;
            position: relative;
            display: block;
            margin: 0 auto;
            border-radius: $border-radius-default;
        }

        &#banner-left {
            order: 1;
            display: none;
            border-radius: $border-radius-default;

            @media screen and (min-width: 768px) {
                display: block;

                .banner-picture {
                    float: left;
                }
            }
        }

        &#banner-right {
            order: 0;

            @media screen and (min-width: 768px) {
                margin: auto;

                .banner-picture {
                    float: right;
                }
            }
        }

        .banner-picture,
        .big-hero-image-wrapper {
            width: 345px;
            height: 220px;
            margin: 0 auto;

            @media screen and (min-width: 320px) {
                width: 289px;
                height: 163px;
            }
            @media screen and (min-width: 375px) {
                width: 343px;
                height: 193px;
            }
            @media screen and (min-width: 425px) {
                width: 392px;
                height: 221px;
            }
            @media screen and (min-width: 450px) {
                width: 420px;
                height: 256px;
            }

            @media screen and (min-width: 524px) {
                width: 460px;
                height: 280px;
            }

            @media screen and (min-width: 635px) {
                width: 520px;
                height: 317px;
            }

            @media screen and (min-width: 768px) {
                width: 358px;
                height: 201px;
            }

            @media screen and (min-width: 800px) {
                width: 373px;
                height: 210px;
            }

            @media screen and (min-width: 910px) {
                width: 427px;
                height: 241px;
            }

            @media screen and (min-width: 1022px) {
                width: 488px;
                height: 274px;
            }

            @media screen and (min-width: 1040px) {
                width: 491px;
                height: 276px;
            }
        }
    }

    @media screen and (min-width: 768px) {
        .banner {
            flex-basis: 50%;

            &#banner-left {
                order: 0;
            }

            &#banner-right {
                order: 1;
            }
        }
    }

    @media screen and (min-width: 890px) {
        .banner {
            flex-basis: calc(50% - 1rem);
        }
    }
}
</style>

<i18n></i18n>
