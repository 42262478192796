<template>
    <div class="homepage-container">
        <UIGridContainer>
            <SearchBarComponent v-if="supportsSearching" />
            <HeaderImageComponent class="fixed-width" />
            <template v-if="occasionCategories?.length > 0">
                <div v-for="category in occasionCategories" :key="category.category_key" class="cards">
                    <OccasionBrandCards
                        class="fixed-width top-spacing"
                        :occasion="currentOccasion"
                        :brands="category.brands"
                        :title-key="category.category_key"
                    ></OccasionBrandCards>
                </div>
            </template>
            <BestSellerComponent :brands="popularBrands" class="fixed-width top-spacing" />
            <CategoryComponent :categories="getActiveCategories" class="fixed-width top-spacing" />
            <CustomerEngagementComponent class="fixed-width mt-0 mt-md-5" />
            <SpotlightComponent
                v-if="spotlightedBrands.length"
                :brands="spotlightedBrands"
                class="fixed-width top-spacing"
            />
        </UIGridContainer>
        <PaymentMethodComponent :methods="paymentMethods" class="fixed-width top-spacing" />
        <UIGridContainer>
            <AppPromotionComponent
                v-if="isMarketingRegion('app') && isMarketingLanguage('app')"
                class="d-md-none mt-4"
                :class="spotlightedBrands.length ? '' : 'top-spacing content'"
            />
            <AboutComponent class="fixed-width top-spacing" />
            <TrustpilotHomepage class="fixed-width top-spacing content d-md-none" />
            <GiftPaperComponent class="fixed-width top-spacing content" />
            <NewsletterComponent class="fixed-width top-spacing content" />
            <AboutArticles
                v-if="articles.length"
                class="top-spacing small articles fixed-width"
                :articles="articles"
                :mobile-limit="2"
            >
                {{ translate('homepagearticles.info') }}
            </AboutArticles>
        </UIGridContainer>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UIGridContainer } from '@dundle/ui/components';
import {
    BestSellerComponent,
    CategoryComponent,
    CustomerEngagementComponent,
    AboutComponent,
    GiftPaperComponent,
    HeaderImageComponent,
    NewsletterComponent,
    PaymentMethodComponent,
    SearchBarComponent,
    SpotlightComponent,
    AppPromotionComponent,
} from '~/components/homepage';
import { AboutArticles } from '~/components/about';
import PageMixin from '~/mixins/PageMixin';
import TranslationMixin from '~/mixins/TranslationMixin';
import TrustpilotHomepage from '~/components/trustpilot/TrustPilotHomepage';
import MarketingMixin from '~/mixins/MarketingMixin';
import OccasionBrandCards from '~/components/occasion/OccasionBrandCards.vue';

export default {
    name: 'HomePage',
    components: {
        BestSellerComponent,
        CategoryComponent,
        CustomerEngagementComponent,
        AboutComponent,
        GiftPaperComponent,
        HeaderImageComponent,
        NewsletterComponent,
        PaymentMethodComponent,
        SearchBarComponent,
        SpotlightComponent,
        AppPromotionComponent,
        AboutArticles,
        UIGridContainer,
        TrustpilotHomepage,
        OccasionBrandCards,
    },
    mixins: [TranslationMixin, MarketingMixin],
    meta: {
        slash: true,
        header: 'version-nosearch',
    },
    async asyncData(ctx) {
        const { app, store } = ctx;
        const pageData = PageMixin.fetchData(ctx);

        // Check if current language is supported by Dundle Magazine if not don't try to get articles
        const language = store.getters['magazineArticles/getLanguagesWithArticles'].includes(app.$locale.language)
            ? app.$locale.language
            : false;
        let articles = [];
        if (language) {
            articles = await app.$api.article.getArticleByBrand('homepage', language, 3);
        }

        const paymentMethods = [
            'dinrex.paypal',
            'applepay',
            'coinpayments',
            'creditcard',
            'creditcard-amex',
            'creditcard-mastercard',
            'creditcard-visa',
            'daopay',
            'fortumo',
            'giropay',
            'ideal',
            'muchbetter',
            'neosurf',
            'neteller',
            'onebip',
            'payconiq',
            'phone',
            'skrill.skrill',
            'sofort',
            'trustly',
        ];

        const categories = store.getters['data/getCategories'].map(category => {
            return {
                name: category.slug,
            };
        });

        const popularBrands = await app.$api.brand.getPopularBrandsMixed();

        const spotlightedBrands = await app.$api.brand.getSpotlightedBrands();

        const currentOccasion = store.getters['occasion/getActiveOccasion'];
        let occasionCategories;
        if (currentOccasion !== 'default') {
            occasionCategories = store.getters['occasion/getOccasionCategories'](currentOccasion);
        }

        return {
            ...(await pageData),
            articles,
            paymentMethods,
            categories,
            popularBrands,
            spotlightedBrands,
            currentOccasion,
            occasionCategories,
        };
    },
    data() {
        return {
            categories: [],
        };
    },
    head() {
        return {
            title: this.translateParams(this.metaTitle),

            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.translateParams(this.metaDescription),
                },
                {
                    hid: 'robots',
                    name: 'robots',
                    content: this.$locale.isTarget() ? 'index, follow' : 'noindex, nofollow',
                },
            ],
        };
    },

    computed: {
        ...mapGetters({
            getActiveCategories: 'data/getActiveCategories',
        }),

        activeCategories() {
            return this.categories.filter(category => this.getActiveCategories.includes(category.name));
        },
        supportsSearching() {
            return this.$locale.isTarget() || this.$locale.language === 'en';
        },
    },
    mounted() {
        this.$gtm?.push({ event: 'page_view_home' });
    },
    httpCaching: {
        maxAge: 60 * 30,
    },

    pageCaching: {
        maxAge: 60 * 30,
    },
};
</script>

<style lang="scss" scoped>
.homepage-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: 1024px) {
        align-items: center;
    }
}

.fixed-width {
    margin: 0 auto;
    @media screen and (min-width: 1024px) {
        width: 994px;
    }
}

.top-spacing {
    margin-top: 3rem;

    @media screen and (min-width: 768px) {
        margin-top: 4rem;
    }

    &.small {
        margin-top: 4.333rem;
    }

    &.content {
        margin-top: 1rem;

        @media screen and (min-width: 768px) {
            margin-top: 4rem;
        }
    }
}

.articles {
    margin: 0 -1rem;
    width: calc(100% + 2rem);

    @media screen and (min-width: 480px) {
        margin: auto;
        width: 100%;
        max-width: 994px;
    }
}
</style>

<i18n src="@dundle/locale/data/articles.json"></i18n>
