<template>
    <div class="position-relative">
        <h2 class="mb-1 mt-0">{{ translate('title') }}</h2>
        <div v-if="snapStep > 5" class="d-none d-md-block" @click="scrollToLeft">
            <UIIcon color="#007cd2" name="chevron-left" class="scroll-chevron left"></UIIcon>
        </div>
        <div ref="productRow" class="category-row d-flex">
            <div v-for="brand in brands" :key="brand.name" class="card" @click="clickSpotlight(brand)">
                <HomepageCard
                    :brand="brand"
                    :colour="brand.color || '#ff394f'"
                    :variant="brands.length <= 5 ? 'large' : 'small'"
                    @click="trackAnalytics(brand)"
                ></HomepageCard>
            </div>
        </div>
        <div v-if="snapStep < brands.length" class="d-none d-md-block" @click="scrollToRight">
            <UIIcon color="#007cd2" name="chevron-right" class="scroll-chevron right"></UIIcon>
        </div>
    </div>
</template>

<script>
import { UIIcon } from '@dundle/ui/components';
import HomepageCard from './HomepageCard.vue';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    name: 'SpotlightComponent',
    components: {
        HomepageCard,
        UIIcon,
    },
    mixins: [TranslationMixin],
    props: {
        brands: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            scrollLeft: 0,
            snapStep: 5,
        };
    },
    mounted() {
        this.$analytics.viewItemList('home_page_spotlight', 'Home Page Spotlight', this.brands);
    },
    methods: {
        scrollToRight() {
            if (this.snapStep === this.brands.length) return;
            this.$refs.productRow.scrollTo({ left: this.scrollLeft + 204, behavior: 'smooth' });
            this.snapStep++;
            this.scrollLeft += 204;
        },
        scrollToLeft() {
            if (this.snapStep <= 4) return;
            this.$refs.productRow.scrollTo({ left: this.scrollLeft - 204, behavior: 'smooth' });
            this.snapStep--;
            this.scrollLeft -= 204;
        },
        clickSpotlight(brand) {
            this.$gtm?.push({ event: 'event_homepage_spotlight_product_clicked', value: brand.name });
        },
    },
};
</script>

<style lang="scss" scoped>
.category-row {
    margin: auto;
    justify-content: space-between;
    overflow-x: scroll;
    flex-wrap: nowrap;

    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    @media screen and (max-width: 996px) {
        width: calc(100% + 2rem);
        padding-right: 1rem;

        margin-left: -1rem;
        padding-left: 1rem;
    }

    .card {
        margin-right: 1rem;

        @media screen and (min-width: 768px) {
            margin-right: 0.6rem;
        }
    }
}

.scroll-chevron {
    position: absolute;

    top: 110px;
    font-size: 1.5rem;
    z-index: 1;

    &:hover {
        cursor: pointer;
        filter: invert(30%) sepia(78%) saturate(1051%) hue-rotate(175deg) brightness(94%) contrast(97%);
    }

    &.right {
        right: -28px;
    }

    &.left {
        left: -28px;
    }
}
</style>

<i18n></i18n>
