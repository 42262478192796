import { render, staticRenderFns } from "./SpotlightComponent.vue?vue&type=template&id=4fb53a2d&scoped=true"
import script from "./SpotlightComponent.vue?vue&type=script&lang=js"
export * from "./SpotlightComponent.vue?vue&type=script&lang=js"
import style0 from "./SpotlightComponent.vue?vue&type=style&index=0&id=4fb53a2d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fb53a2d",
  null
  
)

/* custom blocks */
import block0 from "./SpotlightComponent.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports