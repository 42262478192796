<template>
    <div class="gift-content">
        <h2 class="info">{{ $product.getSimpleName(product) }}</h2>
        <UIImage
            class="product-image"
            :resource="true"
            :src="$product.getImage640(product)"
            :alt="$product.getName(product) + ' not found'"
        />
        <h2 class="mt-4 info">value - {{ $product.getTextValue(product) }}</h2>
        <h2 class="info code-info mt-3">CODE - <span class="code">XXX-XXX-XXX</span></h2>
    </div>
</template>

<script>
import { UIImage } from '@dundle/ui/components';
export default {
    components: {
        UIImage,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.gift-content {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
}

.info {
    font-size: 1rem;

    &.code-info {
        padding: 0.2rem;
        width: 80%;
        border: 1px solid black;
    }
}

.product-image {
    margin-top: 1rem;
    width: 70%;
    border-radius: 12px;
    align-self: center;
}

.code {
    color: $color-text;
    font-size: 0.8rem;
}
</style>
