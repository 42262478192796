<template>
    <UIGridContainer>
        <div class="d-flex flex-column align-items-center mb-4">
            <MagazineLogo class="magazine-logo mb-2" />
            <h2 class="title mt-0"><slot></slot></h2>
            <div class="w-100 d-flex flex-column flex-md-row mt-5 flex-md-wrap flex-lg-nowrap articles-container">
                <BrandRelatedArticles
                    v-for="(article, index) in articles"
                    :key="article.id"
                    :class="[
                        { 'd-none d-md-flex d-lg-none': index >= 3 },
                        { 'd-none d-md-flex d-lg-block': index >= mobileLimit },
                    ]"
                    class="article"
                    :article="article"
                    :new-tab="true"
                />
            </div>
        </div>
    </UIGridContainer>
</template>

<script>
import { UIGridContainer } from '@dundle/ui/components';
import { BrandRelatedArticles } from '~/components/brand';
import { MagazineLogo } from '~/components/blog';
import TranslationMixin from '~/mixins/TranslationMixin';
export default {
    components: {
        BrandRelatedArticles,
        UIGridContainer,
        MagazineLogo,
    },
    mixins: [TranslationMixin],
    props: {
        articles: {
            type: Array,
            required: true,
        },
        mobileLimit: {
            type: Number,
            default: 3,
        },
    },
};
</script>

<style lang="scss" scoped>
.acticles-container {
    flex-wrap: wrap;
}

.article {
    &:first-of-type {
        margin-left: 0;
    }

    @media screen and (min-width: 768px) {
        width: 33%;
        margin-left: 1.9rem;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        margin-left: 0;
        padding-right: 0.5rem;
    }

    @media (min-width: 576px) and (max-width: 768px) {
        min-width: 50%;
        margin-left: 0;
    }
}

.title {
    font-size: 1.3rem;
    line-height: 1.867rem;
    color: $color-primary-dark;
    text-align: center;
    font-weight: 400;
    @media screen and (min-width: 768px) {
        font-size: 1.8rem;
        line-height: 2.333rem;
        text-align: center;
        width: 70%;
    }
}
</style>

<i18n src="@dundle/locale/data/articles.json"></i18n>
