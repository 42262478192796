import { render, staticRenderFns } from "./AboutArticles.vue?vue&type=template&id=0f237cf0&scoped=true"
import script from "./AboutArticles.vue?vue&type=script&lang=js"
export * from "./AboutArticles.vue?vue&type=script&lang=js"
import style0 from "./AboutArticles.vue?vue&type=style&index=0&id=0f237cf0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f237cf0",
  null
  
)

/* custom blocks */
import block0 from "@dundle/locale/data/articles.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Fabout%2FAboutArticles.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports