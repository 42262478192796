<template>
    <UIGridRow class="mx-auto about-container">
        <UIGridCol md="5" class="pl-0">
            <h2 class="mt-0 mt-md-4">
                {{ translate('title') }}
            </h2>
            <p class="paragraph">
                {{ translate('bodytext1', { country: translate('country.' + $locale.region.toUpperCase()) }) }}
            </p>
            <p class="paragraph">
                {{ translate('bodytext2', { country: translate('country.' + $locale.region.toUpperCase()) }) }}
                <nuxt-link :to="$locale.path('about')" class="d-md-none link">
                    {{ translate('linktext') }}
                </nuxt-link>
            </p>
            <nuxt-link
                :to="$locale.path('about')"
                class="d-none d-md-block"
                aria-label="About page"
                @click="clickAbout"
            >
                <UIButton variant="primary" class="about-button">
                    {{ translate('linktext') }}
                </UIButton>
            </nuxt-link>
        </UIGridCol>
        <UIGridCol md="7" class="pr-0 pl-0 pl-md-5 mt-5 mt-md-0 image-col">
            <UIImage
                src="images/home-page/about-us.png"
                :resource="true"
                :width="600"
                alt="About homepage"
                ratio="120:73"
            />
            <nuxt-resource
                class="hexagon d-none d-md-block"
                src="images/about-page/hexagon-pink.svg"
                alt="Red hexagon"
                lazy
            />
        </UIGridCol>
    </UIGridRow>
</template>

<script>
import { UIGridRow, UIGridCol, UIButton, UIImage } from '@dundle/ui/components';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    name: 'AboutComponent',
    components: {
        UIGridRow,
        UIGridCol,
        UIButton,
        UIImage,
    },
    mixins: [TranslationMixin],
    methods: {
        clickAbout() {
            this.$gtm?.push({ event: 'event_homepage_about_link_clicked' });
        },
    },
};
</script>

<style lang="scss" scoped>
.about-button {
    min-width: 14.5rem;
}

.link {
    text-decoration: underline;
}

.paragraph {
    line-height: 1.467rem;
    color: $color-grey-9;
}

.hexagon {
    position: absolute;
    right: 150px;
    top: 120px;
    width: 180px;
}

.image-col {
    @media (min-width: 768px) and (max-width: 996px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
</style>

<i18n src="@dundle/locale/data/countries.json"></i18n>
<i18n></i18n>
