<template>
    <UIGridRow class="d-flex justify-content-center search-wrapper w-100 ml-0">
        <UIGridCol cols="12" md="8" class="d-flex flex-column px-0">
            <h2 class="title d-none d-md-block">{{ translate('title') }}</h2>
            <MenuSearchInput ref="searchInput" :variant="'homepage'" />
        </UIGridCol>
    </UIGridRow>
</template>

<script>
import { UIGridRow, UIGridCol } from '@dundle/ui/components';
import { MenuSearchInput } from '~/components/menu';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    name: 'SearchBarComponent',
    components: {
        MenuSearchInput,
        UIGridRow,
        UIGridCol,
    },
    mixins: [TranslationMixin],
    methods: {
        onClickSearch() {
            this.$refs.searchInput.forceFocus();
        },
    },
};
</script>

<style lang="scss" scoped>
.search-wrapper {
    height: 4rem;
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    @media screen and (min-width: 768px) {
        margin-bottom: 8.333rem;
    }
}
.search-icon-wrapper {
    font-size: 1.75rem;
    color: $color-header-text-bold;
}

.title {
    margin-bottom: 0.3333rem;
    font-size: 1.2rem;
}
</style>

<i18n></i18n>
