<template>
    <UIGridRow class="mx-auto giftpaper-expo">
        <UIGridCol class="pl-0 d-none d-lg-flex" @click="navigateGiftWrap">
            <div class="section">
                <UIImage src="images/home-page/gift-card-design-phone.png" :resource="true" class="phone" />
            </div>
        </UIGridCol>
        <UIGridCol class="px-0 px-lg-2" @click="navigateGiftWrap">
            <div class="section">
                <div class="text d-block d-lg-none">
                    <h2>
                        <span class="title mx-auto">{{ translate('title') }}</span>
                    </h2>
                    <p class="text-center text-white desc-desktop">
                        {{ translate('bodytext') }}
                    </p>
                </div>
                <div class="d-flex mt-3 mt-lg-0 justify-content-lg-center">
                    <GiftTemplate image="images/gift/images/birthday-5-thumbnail.png" class="giftwrap-preview" />
                    <GiftTemplate
                        image="images/gift/images/general-7-thumbnail.png"
                        class="giftwrap-preview ml-4 d-lg-none"
                    />
                    <GiftTemplate
                        image="images/gift/images/general-1-thumbnail.png"
                        class="giftwrap-preview ml-4 d-none d-md-block d-lg-none"
                    />
                    <GiftTemplate
                        image="images/gift/images/general-6-thumbnail.png"
                        class="giftwrap-preview ml-4 d-none d-md-block d-lg-none"
                    />
                </div>
            </div>
        </UIGridCol>
        <UIGridCol class="d-none d-lg-flex" @click="navigateGiftWrap">
            <div class="section">
                <GiftTemplate image="images/gift/images/halloween-1-thumbnail.png" class="giftwrap-preview" />
            </div>
        </UIGridCol>
        <UIGridCol class="d-none d-lg-flex" @click="navigateGiftWrap">
            <div class="section">
                <GiftTemplate image="images/gift/images/valentine-6-thumbnail.png" class="giftwrap-preview" />
            </div>
        </UIGridCol>
        <UIGridCol class="d-none d-lg-flex">
            <div class="section text">
                <div>
                    <span class="title">{{ translate('title') }}</span>
                    <p class="paragraph">
                        {{ translate('bodytext') }}
                    </p>
                </div>
            </div>
        </UIGridCol>
    </UIGridRow>
</template>

<script>
import { UIGridRow, UIGridCol, UIImage } from '@dundle/ui/components';
import { GiftTemplate } from '~/components/gift';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    name: 'GiftPaperComponent',
    components: {
        UIGridRow,
        UIGridCol,
        GiftTemplate,
        UIImage,
    },
    mixins: [TranslationMixin],
    methods: {
        navigateGiftWrap() {
            window.open(`https://dundle.com/magazine/en/how-to-create-custom-printable-gift-cards/`, '_blank');
        },
    },
};
</script>

<style lang="scss" scoped>
.col {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.section {
    width: 100%;
    background-color: $color-section-bg;
    border-radius: $border-radius-default;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 1rem;

    @media screen and (min-width: 1024px) {
        flex-direction: row;
        height: 300px;
        padding: 0;
        cursor: pointer;
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;

        background-image: url(https://images.dundle.com/resources/images/about-page/pattern-dundle-white.svg);
        background-position: center center;
        background-size: 175px 183px;
        opacity: 0.15;
        background-repeat: repeat;
    }

    .text,
    &.text {
        background-color: initial;
        cursor: initial;
        text-align: center;
        margin-bottom: 1rem;

        @media screen and (min-width: 1024px) {
            text-align: left;
        }

        .title {
            font-size: 1.25rem;
            line-height: 2rem;
            font-weight: 900;
            color: $color-grey-9;
            background-color: $color-text-shadow-light;
            z-index: 1;
            box-decoration-break: clone;
            padding: 0 0.3rem;
            margin-bottom: 1rem;
            text-transform: uppercase;

            @media screen and (min-width: 1024px) {
                color: $color-text-shadow-light;
                background-color: $color-grey-9;
                width: min-content;
                line-height: 1.7rem;
            }
        }

        .paragraph {
            line-height: 1.2rem;
            margin-top: 0.5rem;
        }
    }

    .desc-desktop {
        @media (min-width: 768px) and (max-width: 1024px) {
            max-width: 70%;
            margin: 0 auto;
        }
    }

    .giftwrap-preview {
        max-width: 130px;
        &:hover {
            cursor: default;
        }
    }

    .phone {
        z-index: 1;
    }
}
</style>

<i18n></i18n>
