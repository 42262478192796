import { render, staticRenderFns } from "./GiftTemplate.vue?vue&type=template&id=36c01df0&scoped=true"
import script from "./GiftTemplate.vue?vue&type=script&lang=js"
export * from "./GiftTemplate.vue?vue&type=script&lang=js"
import style0 from "./GiftTemplate.vue?vue&type=style&index=0&id=36c01df0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36c01df0",
  null
  
)

/* custom blocks */
import block0 from "@dundle/locale/data/guides.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fbuilds%2Fkorsit-bv%2Fdundle%2Fservice%2Fdundle.com%2Fcomponents%2Fgift%2FGiftTemplate.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports