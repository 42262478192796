<template>
    <div class="position-relative">
        <h2 class="mb-1 mt-0">
            {{ translate('title', { country: translate('country.' + $locale.region.toUpperCase()) }) }}
        </h2>
        <div v-if="snapStep > 4" class="d-none d-md-block" @click="scrollToLeft">
            <UIIcon color="#007cd2" name="chevron-left" class="scroll-chevron left"></UIIcon>
        </div>
        <UIGridRow ref="productRow" class="category-row">
            <div v-for="brand in brands" :key="brand.name" class="card" @click="clickBestSeller(brand)">
                <HomepageCard :brand="brand" :colour="brand.color || '#ff394f'" variant="large"></HomepageCard>
            </div>
        </UIGridRow>
        <div v-if="snapStep < brands.length" class="d-none d-md-block" @click="scrollToRight">
            <UIIcon color="#007cd2" name="chevron-right" class="scroll-chevron right"></UIIcon>
        </div>
    </div>
</template>

<script>
import { UIGridRow, UIIcon } from '@dundle/ui/components';
import HomepageCard from './HomepageCard.vue';
import TranslationMixin from '~/mixins/TranslationMixin';
import SettingsMixin from '~/mixins/SettingsMixin';

export default {
    name: 'BestSellerComponent',
    components: {
        UIGridRow,
        HomepageCard,
        UIIcon,
    },
    mixins: [TranslationMixin, SettingsMixin],
    props: {
        brands: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            scrollLeft: 0,
            snapStep: 4,
        };
    },
    mounted() {
        this.$analytics?.viewItemList('home_page_bestseller', 'Home Page Bestseller', this.brands);
    },
    methods: {
        scrollToRight() {
            if (this.snapStep === this.brands.length) return;
            this.$refs.productRow.$el.scrollTo({ left: this.scrollLeft + 251.5, behavior: 'smooth' });
            this.snapStep++;
            this.scrollLeft += 251.5;
        },
        scrollToLeft() {
            if (this.snapStep <= 4) return;
            this.$refs.productRow.$el.scrollTo({ left: this.scrollLeft - 251.5, behavior: 'smooth' });
            this.snapStep--;
            this.scrollLeft -= 251.5;
        },
        clickBestSeller(brand) {
            this.$analytics?.viewItem(brand.products, brand);
            this.$gtm?.push({ event: 'event_homepage_best_seller_clicked', value: brand.name });
            this.$analytics?.selectItemFromItemList(
                'home_page_bestseller',
                'Home Page Bestseller',
                brand.products,
                brand
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.category-row {
    margin: auto;
    justify-content: space-between;
    overflow-x: scroll;
    flex-wrap: nowrap;
    position: relative;

    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    @media screen and (max-width: 996px) {
        width: calc(100% + 2rem);
        padding-right: 1rem;

        margin-left: -1rem;
        padding-left: 1rem;
    }

    .card {
        margin-right: 1rem;

        @media screen and (min-width: 768px) {
            margin-right: 0.6rem;
        }
    }
}

.scroll-chevron {
    position: absolute;

    top: 135px;
    font-size: 1.5rem;
    z-index: 1;

    &:hover {
        cursor: pointer;
        filter: invert(30%) sepia(78%) saturate(1051%) hue-rotate(175deg) brightness(94%) contrast(97%);
    }

    &.right {
        right: -28px;
    }

    &.left {
        left: -28px;
    }
}
</style>

<i18n src="@dundle/locale/data/countries.json"></i18n>
<i18n></i18n>
