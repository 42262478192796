<template>
    <div :class="`template-wrapper ${side}-side`">
        <div :class="[`paper-back ${side}`, { variant: !gift }]" />
        <UIImage
            v-if="(gift || image) && side === 'front'"
            class="template"
            :class="{ hoverable: !gift }"
            :src="gift ? getTheme(gift.templateId) : image"
            :resource="true"
            :width="200"
            :height="282"
            alt="Template preview"
        />
        <div v-if="side === 'right'" class="empty-page right" />
        <div v-if="side != 'front' && (product.brand || message)" class="preview-container">
            <div
                v-if="side === 'left'"
                class="gift-message small-message scroll-enabled"
                v-html="gift ? gift.message : message"
            />
            <div v-if="side === 'right'">
                <GiftTemplateContent :product="product" />
            </div>
            <div
                v-if="side === 'back'"
                class="product-instructions"
                v-html="translateParams(product.brand.redeemInstructions, product)"
            />
        </div>
        <div v-if="side === 'left' && gift" class="empty-page left" />
        <div v-else-if="side === 'left' && !gift" class="empty-page left no-transparency">
            <GiftTemplateContent v-if="product.brand" :product="product" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UIImage } from '@dundle/ui/components';
import GiftTemplateContent from './GiftTemplateContent.vue';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    components: {
        GiftTemplateContent,
        UIImage,
    },
    mixins: [TranslationMixin],
    props: {
        gift: {
            type: Object,
            required: false,
        },
        side: {
            type: String,
            required: false,
            default: 'front',
        },
        image: {
            type: String,
            required: false,
        },
        message: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            product: {},
        };
    },
    async fetch() {
        if (this.gift || this.getGift.gift.productId) {
            this.product = await this.$api.product.getById(
                this.gift ? this.gift.productId : this.getGift.gift.productId
            );
        }
    },
    computed: {
        ...mapGetters({
            getTheme: 'gift/getTheme',
            getGift: 'gift/getGift',
        }),
    },
};
</script>

<style lang="scss" scoped>
.template-wrapper {
    position: relative;
    &.front-side,
    &.back-side {
        padding-top: 0.667rem;
    }
    &.left-side {
        margin-right: 1rem;
        @media screen and (min-width: 768px) {
            margin-right: 13.2rem;
        }
    }
    &.right-side {
        margin-left: 1rem;
        @media screen and (min-width: 768px) {
            margin-left: 13.2rem;
        }
    }
}

.template {
    position: relative;

    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.paper-back {
    position: absolute;
    z-index: 0;
    &.front {
        top: 1.8%;
        background: linear-gradient(270deg, #ebebeb 0%, #d9d9d9 100%);
        height: 6.4%;
        width: 90%;
        -webkit-transform: skewY(-3deg);
        transform: skewY(-3deg);

        &.variant {
            -webkit-transform: skewY(-6deg);
            transform: skewY(-6deg);
        }
    }
    &.back {
        top: 1.9%;
        right: 0;
        background: linear-gradient(270deg, #ebebeb 100%, #d9d9d9 0%);
        height: 5.55%;
        width: 90%;
        -webkit-transform: skewY(3deg);
        transform: skewY(3deg);
    }
}

.preview-container {
    height: 19rem;
    border: 1px solid #dcdcdc;
    overflow: hidden;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    position: relative;
    background-color: $color-bg;
}

.scroll-enabled {
    overflow-y: scroll;
}

.gift-message {
    overflow-wrap: break-word;
}

.product-instructions {
    user-select: none;
    padding: 1rem;
    font-size: 0.333rem;
    overflow: hidden;

    ::v-deep h2 {
        font-size: 6px;
        margin-top: 1rem;
    }

    ::v-deep p,
    h2 {
        margin-bottom: 0.2rem !important;
    }

    ::v-deep ul {
        padding-left: 1rem;
    }
}

.small-message {
    padding: 0.467rem;
    font-size: 0.65rem;
    font-weight: 600;
    overflow: hidden;
}

.hoverable {
    &:hover {
        cursor: pointer;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35);
    }
}

.empty-page {
    height: 19rem;
    width: 13.333rem;
    background-color: $color-bg;
    opacity: 0.5;
    position: absolute;
    top: 0;

    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);

    &.left {
        right: -100%;
    }

    &.right {
        left: -100%;
    }
}

.no-transparency {
    opacity: 1;
}
</style>

<i18n src="@dundle/locale/data/guides.json"></i18n>
