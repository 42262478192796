<template>
    <div class="trustpilot-container">
        <Trustpilot class="trustpilot-homepage" light
small
/>
    </div>
</template>

<script>
import Trustpilot from '~/components/layout/sidebar/TrustpilotComponent';

export default {
    components: {
        Trustpilot,
    },
};
</script>

<style lang="scss" scoped>
.trustpilot-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 188px;
    background-color: #000032;
    border-radius: $border-radius-default;
}
</style>
