<template>
    <div class="payment-exposition">
        <div class="text mb-4">
            <h2 class="mt-5 px-1">
                <span class="title">{{ translate('title') }}</span>
            </h2>
            <p class="description mb-0">
                {{ translate('bodytext') }}
            </p>
            <span class="methods-link" @click.stop.prevent="showPaymentMethodsModal()">{{
                translate('linktext')
            }}</span>
        </div>
        <div class="methods first mb-1 mb-md-5">
            <a v-for="(method, index) in methods" :key="method" @click="$event = showPaymentMethodsModal()">
                <nuxt-resource
                    :src="`images/home-page/payment-methods/${method}.svg`"
                    class="payment-method d-none"
                    :class="index >= 13 ? 'd-md-none d-lg-flex' : 'd-md-flex'"
                    :alt="`Paymentmethod ${method}`"
                    lazy
                />
            </a>
            <nuxt-resource
                v-for="(method, index) in firstRow"
                :key="method + index"
                :src="`images/home-page/payment-methods/${method}.svg`"
                class="payment-method d-md-none"
                :alt="`Paymentmethod ${method}`"
                lazy
            />
        </div>
        <div class="methods second d-md-none mb-5">
            <nuxt-resource
                v-for="(method, index) in secondRow"
                :key="method + index"
                :src="`images/home-page/payment-methods/${method}.svg`"
                class="payment-method"
                :alt="`Paymentmethod ${method}`"
                lazy
            />
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    name: 'PaymentMethodComponent',
    mixins: [TranslationMixin],
    props: {
        methods: {
            type: Array,
            required: true,
        },
    },
    computed: {
        firstRow() {
            const firstMethods = this.methods.slice();
            return firstMethods.splice(0, 9).concat(this.methods.slice().splice(0, 9));
        },
        secondRow() {
            const secondMethods = this.methods.slice();
            return secondMethods
                .splice(12, this.methods.length)
                .concat(this.methods.slice().splice(12, this.methods.length));
        },
    },
    methods: {
        ...mapActions({
            showModal: 'ui/showModal',
        }),
        showPaymentMethodsModal() {
            this.$analytics?.event('view_payment_methods_home_page');

            this.showModal({ name: 'footer-payment-methods', isLargeModal: true });
        },
    },
};
</script>

<style lang="scss" scoped>
@keyframes slideshow {
    100% {
        transform: translateX(-50%);
    }
}

@keyframes slideshow-reverse {
    100% {
        transform: translateX(54.602%);
    }
}

.payment-exposition {
    width: 100%;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;

    background-color: $color-grey-8;

    @media screen and (min-width: 768px) {
        border-radius: $border-radius-default;
    }

    @media screen and (min-width: 1024px) {
        max-width: 994px;
    }

    .text {
        text-align: center;
    }

    .title {
        background-color: $color-bg;
        font-weight: 900;
        margin-bottom: 1rem;
        text-transform: uppercase;
        line-height: 2rem;
        padding: 0 0.3rem;
    }

    .description {
        max-width: 70%;
        text-align: center;
        color: $color-header;
        line-height: 1.2rem;
        margin: 0 auto;
    }

    .methods-link {
        color: $color-header;
        text-decoration: underline;
        text-overflow: ellipsis;
        white-space: nowrap;
        z-index: 1;

        &:hover {
            cursor: pointer;
        }
    }

    .methods {
        width: calc(100% + 4rem);
        margin-left: -2rem;

        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        @media screen and (min-width: 768px) {
            width: 100%;
        }

        .payment-method {
            width: 69px;
            height: 60px;

            margin-top: 1rem;
            margin-right: 1rem;
            z-index: 1;
            transition: transform 0.2s linear;

            @media screen and (min-width: 768px) {
                &:hover {
                    transform: scale(1.25);
                }
            }

            &:hover {
                cursor: pointer;
            }
        }

        &.first {
            animation: slideshow 70s linear infinite;

            @media screen and (min-width: 768px) {
                flex-wrap: wrap-reverse;
                justify-content: center;
                margin-right: 0;
                animation: none;
                margin-left: 0;
            }

            @media (min-width: 768px) and (max-width: 1024px) {
                max-width: 90%;
            }
        }

        &.second {
            margin-right: 30.884rem;
            animation: slideshow-reverse 70s linear infinite;
            .payment-method {
                margin-right: 0;
                margin-left: 1rem;
            }
        }
    }

    &::before {
        z-index: -100;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;

        background-image: url(https://images.dundle.com/resources/images/about-page/pattern-dundle-white.svg);
        background-position: center center;
        background-size: 175px 183px;
        opacity: 0.04;
        background-repeat: repeat;
    }
}
</style>

<i18n></i18n>
