<template>
    <div>
        <h2 class="mb-1 mt-0">{{ translate('title') }}</h2>
        <UIGridRow class="category-row">
            <div v-for="category in categories" :key="category" class="card" @click="categoryClicked(category)">
                <HomepageCard
                    :link="$category.getPath(category)"
                    :title="translate(`category.${category}.name`)"
                    :image="getImage(category)"
                    :colour="getColour(category)"
                    :variant="categories.length < 5 ? 'large' : 'small'"
                ></HomepageCard>
            </div>
        </UIGridRow>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { UIGridRow } from '@dundle/ui/components';
import HomepageCard from './HomepageCard.vue';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    name: 'CategoryComponent',
    components: {
        UIGridRow,
        HomepageCard,
    },
    mixins: [TranslationMixin],
    props: {
        categories: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            parsedCategories: 'data/getCategories',
        }),
    },
    methods: {
        getImage(category) {
            return this.$store.getters['data/getCategoryImage'](category);
        },
        getColour(category) {
            return this.$store.getters['data/getCategoryColour'](category);
        },
        categoryClicked(category) {
            this.$gtm?.push({ event: 'event_homepage_category_clicked', value: category });
        },
    },
};
</script>

<style lang="scss" scoped>
.category-row {
    margin: auto;
    justify-content: flex-start;
    overflow-x: scroll;
    flex-wrap: nowrap;

    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    @media screen and (max-width: 996px) {
        width: calc(100% + 2rem);
        padding-right: 1rem;

        margin-left: -1rem;
        padding-left: 1rem;
    }

    .card {
        width: 100%;
        &:not(:first-child) {
            padding-left: 0.3rem;
        }
        &:not(:last-child) {
            padding-right: 0.3rem;
        }
        .image {
            width: 100%;
        }
    }
}
</style>

<i18n src="@dundle/locale/data/categories.json"></i18n>
<i18n></i18n>
