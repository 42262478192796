import TranslationMixin from '~/mixins/TranslationMixin';
export default {
    mixins: [TranslationMixin],

    head() {
        return {
            title: this.translateParams(this.metaTitle),

            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.translateParams(this.metaDescription),
                },
                {
                    hid: 'robots',
                    name: 'robots',
                    content: 'noindex, nofollow',
                },
            ],
        };
    },

    async fetchData({ app, route, isDev }) {
        function warnApiTranslation(origin) {
            if (isDev) {
                console.warn('DevelopWarning - Missing the: ' + origin + ' translation');
            }
            return '';
        }

        try {
            const reversedRoute = app.$locale.reverseRoute(route);

            const routeName = reversedRoute.name;

            const data = await app.$api.page.getBySlug(routeName);

            return {
                metaTitle: data.details.title || warnApiTranslation('data.details.title'),
                metaDescription: data.details.description || warnApiTranslation('data.details.description'),
                metaRobots: data.details.robots || warnApiTranslation('data.details.robots'),
                title: data.title || warnApiTranslation('data.title'),
                content: data.content || warnApiTranslation('data.content.value'),
            };
        } catch {
            console.warn(`Could not load page: "${route.fullPath}"`);
        }
    },
};
