<template>
    <div>
        <h2 class="mb-1 mt-0 d-none d-md-block">{{ translate('header') }}</h2>
        <UIGridRow class="mx-auto wrapper">
            <UIGridCol cols="12" md="10" lg="7" class="description-list px-0 mb-md-3 mb-lg-0">
                <div class="loyalty d-md-none">
                    <nuxt-resource
                        src="images/loyalty/popup/loyalty-popup-top.png"
                        class="chest"
                        alt="Loyalty coins chest graphic"
                        lazy
                        :width="710"
                        :height="427"
                    />
                    <div class="loyalty-content">
                        <h2 class="title">{{ translate('header') }}</h2>
                        <ul>
                            <li>{{ translate('list1') }}</li>
                            <li>{{ translate('list2') }}</li>
                        </ul>
                        <UIButton class="ml-2 mb-3 button" variant="loyalty-minimal" @click="navigateToLoyalty">
                            {{ translate('loyalty.returnPageButton') }}
                        </UIButton>
                    </div>
                </div>
                <div class="loyalty-desktop d-none d-md-flex">
                    <UIImage
                        src="images/home-page/dundle-world/dundle-world-treasure_desktop.png"
                        :quality="100"
                        :resource="true"
                        class="chest"
                        :width="160"
                        :height="131"
                        alt="Loyalty coins chest graphic"
                    />
                    <div class="info">
                        <ul>
                            <li>{{ translate('list1') }}</li>
                            <li>{{ translate('list2') }}</li>
                        </ul>
                        <UIButton class="ml-2 mb-3 mb-md-0 button" variant="loyalty-minimal" @click="navigateToLoyalty">
                            {{ translate('loyalty.returnPageButton') }}
                        </UIButton>
                    </div>
                </div>
            </UIGridCol>
            <UIGridCol md="10" lg="5" class="pr-0 pl-md-0 pl-lg-2 d-none d-md-block trustpilot-col">
                <TrustpilotHomepage class="trustpilot" />
            </UIGridCol>
        </UIGridRow>
    </div>
</template>

<script>
import { UIGridRow, UIGridCol, UIButton, UIImage } from '@dundle/ui/components';
import TrustpilotHomepage from '~/components/trustpilot/TrustPilotHomepage';
import TranslationMixin from '~/mixins/TranslationMixin';

export default {
    name: 'CustomerEngagementComponent',
    components: {
        UIGridRow,
        UIGridCol,
        UIButton,
        TrustpilotHomepage,
        UIImage,
    },
    mixins: [TranslationMixin],
    methods: {
        navigateToLoyalty() {
            this.$gtm?.push({ event: 'event_homepage_loyalty_button_clicked' });
            this.$router.push(this.$locale.path('reward-info'));
        },
    },
};
</script>

<style lang="scss" scoped>
.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 1024px) {
        display: -webkit-box;
    }
}

.description-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 666px;
    }

    .loyalty {
        overflow: hidden;
        position: relative;

        background: url(https://images.dundle.com/resources/images/loyalty/intro-step-1-bottom.svg);
        background-size: 92%;
        background-position: bottom center;
        background-repeat: no-repeat;

        padding: 2rem 0;

        .title,
        .text {
            max-width: 90%;
        }

        .title {
            margin-top: 1rem;
        }

        .text {
            color: $color-grey-9;
            margin-bottom: 1rem;
        }

        @media screen and (min-width: 768px) {
            margin-top: 5rem;

            max-width: 355px;
        }

        .loyalty-content {
            background: url(https://images.dundle.com/resources/images/loyalty/intro-step-1-middle.svg) repeat-y;
            background-size: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            padding: 1rem;
            margin-right: 1rem;
            margin-left: 1rem;
        }

        .chest {
            min-width: 100%;
            margin-left: 0rem;
            margin-bottom: -0.2rem;
        }

        .button {
            width: 90%;
        }
    }

    .loyalty-desktop {
        background: url(https://images.dundle.com/resources/images/home-page/dundle-world/dundle-world-board_desktop.svg)
            no-repeat;
        background-size: 100%;
        align-items: center;
        padding: 0.8rem;
        color: $color-grey-7;
        flex: 1;

        @media (min-width: 768px) and (max-width: 1024px) {
            margin-top: 1rem;
            min-height: 14rem;
        }

        img {
            align-self: auto;
            margin-left: 1.5rem;
            transform: scaleX(-1);
        }

        .info {
            display: flex;
            flex-direction: column;
            justify-content: center;

            padding: 0 1.8rem;

            height: 100%;

            li {
                font-size: 1rem;
            }
        }

        ul {
            padding-left: 1.5rem;
        }

        .button {
            width: 100%;
            margin-bottom: 0.1rem;
        }
    }
}

.world-image {
    border-radius: $border-radius-default;
    min-height: 188px;
    object-fit: cover;
}

.button {
    width: 16rem;
}

.title {
    line-height: 1.5rem;
}

.usps {
    line-height: 2rem;
}

.trustpilot-col {
    @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 666px;
    }
}

.trustpilot {
    @media (min-width: 768px) and (max-width: 1024px) {
        max-width: 666px;
    }
}
</style>

<i18n src="@dundle/locale/data/loyalty.json"></i18n>
<i18n></i18n>
